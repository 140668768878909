<template>
    
    

    <DataLookupDropdown :dataLookupControl="lookupControl" :modelValue = "modelValue" :value="value">
        <template #target="scope">    
            <slot name="target" :target="scope.target" :open="scope.open"></slot>  
        </template>
        <template #listContainer>
            <DataLookupList :dataLookupControl="lookupControl">
                <template #actions><slot name="actions"></slot></template>
                <template #toolbar><slot name="toolbar"></slot></template>
                <slot></slot>
            </DataLookupList>
        </template>
    </DataLookupDropdown>
</template>
<script setup>
    import DataLookupDropdown from 'o365.vue.components.DataLookupDropdown.vue';
    import DataLookupList from 'o365.vue.components.DataLookupList.vue';
    import DataLookupControl from 'o365.modules.DataLookup.ts';

    import {ref,watch,computed,onMounted,useSlots} from 'vue';
    const slots = useSlots();

    const props = defineProps({
        dataObject:{
            required:true
        },
        bind: Function,
        columns:Array,
        filterRow:{
            required:false
        },
        multiselect:{
            type:Boolean,
            default:false
        },
        noSearch:{
            type:Boolean,
            default:false
        },
        noClear:{
            type:Boolean,
            default:false
        },
        whereClause:String,
        bindClear:Function,
        disableRecent: Boolean,
        disablePinned: Boolean,
        //dropdown options
        placement: {
            type: String,
            default: 'bottom-start',
        },
        height: {
            type: Number,
            default() {
                return window.innerHeight > 800 ? 500 : 300
            },
        },
        maxWidth: Number,
        suppressClickEvent: Boolean,
        openonfocus: Boolean,
        modelValue:{},
        value:{}

    });

    const lookupControl = new DataLookupControl(props);

    watch(props,(newValues)=>{
        lookupControl.whereClause = newValues.whereClause;
   
    })

    onMounted(()=>{

     
    })

    function activateEditor() {
        lookupControl.dropdown?.value?.target?.focus();
        lookupControl.dropdown?.value?.open();
    }

    defineExpose({lookupControl,activateEditor});

</script>
<style>
    .o365-data-lookup{
        z-index:1021;
    }
     .o365-header-row [o365-field='o365_pinned']{
        display:none;
    }
</style>